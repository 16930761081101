/** go-top-skin */
.go-top {
    display:block;
    text-align: center;
    line-height:1;
    position: absolute;
    bottom:-100%;
    overflow: hidden;
    @include opacity(0);
    z-index: 99;
    &:before {
        content:'';
        display:block;
    }
    &.block{
        @include opacity(1);
        position: fixed;
    }
    &.block.end {
        position: absolute;
        bottom: auto;
    }
}
.gotop-tp01{
    right: 15px;
    font-size:0;
    &:before {
        width:20px; height:14px;
        background:url(#{$img}gotop01.png) center no-repeat;
        background-size:contain !important;
        position:absolute;
        top:8px; left:50%;
        transform: translate(-50%,0);
    }
    &.block {
        bottom: 30px; right:2%;
        width: 35px; height:35px;
        background:rgba(0,0,0,0.8);
        @include radius(50%);
    }
    &.end { margin: -20px 0 0; }
}
.gotop-tp02{
    width: 0; height: 0;
    right: 15px;
    font-size:0;
    text-indent:-9999px;
    background:$color1;
    &:before {
        width:10px; height:10px;
        @extend %fake-arrow;
        border-width:2px;
        border-color:#fff;
        @extend %rotate-top;
        position:absolute;
        top:50%; left:50%;
        margin:-4px 0 0 -5px;
    }
    &.block {
        bottom: 30px; right:2%;
        width: 30px; height:30px;
        @include radius(50%);
    }
    &.end { margin: -15px 0 0; }
}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    .gotop-tp01{
        &:before {top:16px;}
        &.block {
            width:70px; height:70px;
            padding:40px 0 0;
            text-align:center;
            @include font-size(13);
            color:#fff;
        }
        &.end { margin: -40px 0 0; }
        &:hover{background:#000;}
    }
    .gotop-tp02{
        &:before {
            width:20px; height:20px;
            margin:-5px 0 0 -9px;
            transform:scale(1,0.9) rotate(45deg);
        }
        &.block {width:60px; height:60px;}
        &.end { margin: -30px 0 0; }
        &:hover{background:darken($color1,15%);}
    }
}
@media screen and (min-width: 1200px) {}
@media screen and (min-width: 1500px) {
    .gotop-tp01 { right:150px;}
}