/** form */
.fill-form {
    margin:0 auto;
    overflow:hidden;
    i {
        font-family:Arial, $font-base;
        font-style:normal;
        font-weight:900;
        color:#d90000;
        text-shadow:0.5px 0 0 #d90000;
    }
    ul, li {margin:0; padding:0; list-style:none outside none;}
    ul {font-size:0;}
    li {position:relative;}
    ul, li, .fill-table, .label, .box, .bd-wrap{overflow:hidden;}
    .full {
        width:100%;
        max-width:none;
        .bd-wrap{max-width:none;}
    }
    .box {width:100%;}
    .bd-wrap {
        position:relative;
        select, input, textarea {
            display:block;
            width:100%;
            &[type="checkbox"], &[type="radio"]{width:16px; height:16px;}
        }
    }
    .choose{
        label{
            margin:0 25px 0 0;
            padding:3px 0;
            &, input, span{
                display:inline-block;
                vertical-align:middle;
            }
            &:last-child{margin-right:0;}
            span{
                @extend %font-size14-15;
                line-height:1;
                padding:0 0 0 6px;
            }
        }
    }
    .submit-wrap {
        text-align:center;
        padding:20px 0 0;
        ul, li{margin: 0; padding: 0; list-style: none outside none;}
        ul{display:flex;}
        li{
            flex: 1;
            padding:0 2px;
        }
    }
}
.fill-tp01, .fill-tp02, .fill-tp03, .fill-tp04, .fill-tp05{
    .prompt{
        @include font-size(14);
        line-height:2;
        padding:0 0 20px;
    }
    li{
        text-align:left;
        margin:0 auto 15px;
    }
    .label{
        @include font-size(14);
        margin:0 0 5px;
    }
    .bd-wrap {
        position:relative;
        select, input, textarea {
            color:$color-base;
            &:focus{border:1px solid $color1;}
        }
        select option{color:#555;}
        textarea {height:120px;}
    }
    .seccode, .code, button{
        display:inline-block;
        vertical-align:top;
        width:49%;
    }
    .code{
        position:relative;
        margin:0 0 0 2%;
        img{
            display:block;
        }
        &:before{
            content:'';
            display:block;
            width:20px; height:20px;
            background-size:contain !important;
            position:absolute;
            bottom:5px; right:5px;
        }
    }
    .btn{
        background:$color1;
        border:2px solid $color1;
        @include font-size(14);
        font-weight:bold;
        color:#fff;
        padding:0 5px;
        line-height:40px;
    }
}
.fill-tp01, .fill-tp04{
    i{transform:scale(1.3,1.3) translate(5px,0);}
    .condition{
        @include font-size(12);
        color:$color1;
        padding:5px 0 0;
    }
    .code{
        max-width:150px;
    }
    .btn{width:100%;}
}
.fill-tp01{
    .bd-wrap {
        select, input, textarea {
            background:rgba(100,100,100,0.1);
            border:1px solid rgba(0,0,0,0);
        }
    }
}
.fill-tp04{
    .bd-wrap {
        select, input, textarea {
            background:$gray01;
            border:1px solid rgba(255,255,255,0);
        }
    }
}

.fill-tp02, .fill-tp03{
    .bd-wrap {
        select, input, textarea {
            background:$body-bg;
            border:1px solid $gray03;
        }
    }
}
.fill-tp02{
    .code{max-width:176px;}
    .btn{
        @include radius(40px);
        &+button{margin:0 0 0 2%;}
    }
    .btn-reset{
        background:$gray03;
        border:2px solid $gray03;
    }
}
.fill-tp03{
    .code{max-width:212px;}
    .btn{
        width:220px;
        font-style:italic;
    }
}
.fill-tp05{
    .bd-wrap {
        select, input, textarea {
            background:$body-bg;
            border:1px solid $gray03;
        }
    }
    .submit-wrap{padding:20px 0 0;}
    .btn{width:100%;}
}
@media screen and (min-width: 480px){
    .fill-tp01, .fill-tp02, .fill-tp03, .fill-tp04, .fill-tp05{
        .prompt{text-align:center;}
        .label {
            float:left;
            margin:10px 0 0;
        }
        .box {width:auto;}
    }
    .fill-tp01, .fill-tp02, .fill-tp03, .fill-tp04{
        .label{width:110px;}
        .box{margin:0 0 0 125px;}
        .choose{
            padding:5px 0;
            label{margin:5px 25px 5px 0;}
        }
    }
    .fill-tp03{
        .submit-wrap{text-align:left;}
    }
    .fill-tp05{
        .label{width:90px;}
        .box{margin:0 0 0 105px;}
    }
}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    .fill-tp01, .fill-tp02, .fill-tp03, .fill-tp05{
        .prompt{padding:0 0 30px;}
        .fill-table{transform:translate(-2%,0);}
    }
    .fill-tp04{
        .prompt{padding:15px 0;}
    }
}
@media screen and (min-width: 769px) {
    .fill-tp01, .fill-tp02, .fill-tp03, .fill-tp04, .fill-tp05{
        .condition{padding:10px 0;}
        li{
            margin:0 auto 20px;
            padding:0 20px;
        }
        .label{@include font-size(15);}
        .btn{
            @include font-size(15);
            &:hover{
                background:none;
                color:$color1;
            }
        }
    }
    .fill-tp02, .fill-tp03{
        li{
            display:inline-block;
            vertical-align:top;
            width: 49.999%;
        }
    }
    .fill-tp02{
        .seccode{width:36%;}
        .submit-wrap{
            float:right;
            width:41%;
            text-align:right;
            padding:0;
            button{
                width:140px;
                &+button{margin:0 0 0 4%;}
            }
        }
        .btn-reset:hover{
            color:#D92C1B;
            border:2px solid #D92C1B;
        }
    }
    .fill-tp03{
        .submit-wrap{padding:5px 0 0;}
    }
}
