/** video */
.video-wrap, .map-wrap {
    position:relative;
    iframe{
        position: absolute;
        width: 100%;  height: 100%;
    }
}
.video-wrap {
    height:0;
    padding:56.45% 0 0;
    iframe { left:0; top:0; }
}
.map-wrap{
    position: relative;
    min-height:300px;
}
.map-tp01{height:300px;}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    .map-tp01{height:400px;}
}
@media screen and (min-width: 1400px) {

}
