@mixin font-size($num: 12) {
    font-size: $num + px;
    font-size: ($num / 10) + rem;
}
@mixin radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin ellipsis ($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
}
@mixin fake-block() {
    content:'';
    display:block;
    width:100%; height:100%;
    position:absolute;
    left:50%; top:50%;
    transform: translate(-50%,-50%);
    z-index:1;
    transition: .3s;
}
@mixin fake-border($w,$color) {
    content:'';
    display:block;
    width:100%; height:100%;
    border:$w solid $color;
    position:absolute;
    left:50%; top:50%;
    transform: translate(-50%,-50%);
    z-index:1;
    transition: .3s;
}


%tinyscroll {
    position:relative;
    .viewport {
        height: 570px;
        overflow: hidden;
        position: relative;
    }
    .overview {
        position: relative;
        left: 0;
        top: 0;
    }
    .scrollbar {
        position: absolute;
        right:-20px;
        /*margin:0 0 5px;
        clear:both;
        height:15px;
        background:transparent url(../images/bg-scrollbar-track-x.png) no-repeat 0 0;*/
    }
    .track {
        position: relative;
        width: 5px;
        z-index: 9;
        @include radius(5px);
        background:#ccc;
        /*background:transparent url(../images/bg-scrollbar-trackend-x.png) no-repeat 100% 0;
        height:15px;*/
        .thumb {
            @include radius(5px);
            //background: $blue;
            cursor: pointer;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
            .end {
                //background: $blue;
                overflow: hidden; width: 5px;
            }
        }
    }
    .disable { display: none; }
}
.noSelect {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}