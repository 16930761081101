/** 分頁樣式 */
.page-limit {
    margin:0 auto;
    text-align:center;
    li {
        display:inline-block;
        vertical-align:middle;
        text-align:center;
        position:relative;
        &.on a{@include opacity(1);}
    }
    a {
        @include opacity(0.7);
        position:relative;
        z-index:1;
    }
    .prev, .next{
        a{@include opacity(1);}
    }
}
.page-tp01, .page-tp02, .page-tp03, .page-tp04{
    padding:30px 0 20px;
    .prev, .next {
        a{
            font-size:0;
            text-indent:-999px;
            overflow:hidden;
            &:after{
                @extend %fake-arrow;
                display:block;
                border-color:$color-base;
                position:absolute;
                z-index:1;
            }
        }
    }
    .prev a:after{
        @extend %rotate-left;
        left:15px;
    }
    .next a:after{
        @extend %rotate-right;
        right:15px;
    }
}
.page-tp01{
    li {padding:0 5px;}
    a {
        @include font-size(16);
        font-weight:bold;
        width:30px; line-height:30px;
    }
    .on a{
        background:$color1;
        color:#fff;
    }
    .prev, .next {
        a:after{
            width:7px; height:7px;
            border-width:2px;
            top:50%;
            margin:-4px 0 0;
        }
    }
}
.page-tp02{
    li {padding:0 5px;}
    a {
        @include font-size(15);
        width:30px; height:40px;
        line-height:40px;
        border-bottom:3px solid $body-bg;
        z-index:1;
        position:relative;
    }
    .on a{
        color:$color1;
        border-bottom:3px solid $color1;
    }
    .prev, .next {
        a:after{
            width:10px; height:10px;
            border-width:1px;
            top:50%;
            margin:-5px 0 0;
        }
    }
}
.page-tp03, .page-tp04{
    li {
        margin:5px 2px;
    }
    a{
        width:36px; height:36px;
        @extend %font-size14-16;
        line-height:36px;
    }
    .on a{color:$color1;}
    .prev, .next {
        a{
            &:after{
                width:8px; height:8px;
                border-width:1px;
                top:50%;
                margin:-4px 0 0;
            }
        }
    }
}
.page-tp03{
    .prev, .next {
        a{border:1px solid $color-light;}
    }
}
.page-tp04{
    a{font-family:'AvantGardeMdBTMedium', $font-base;}
    .prev, .next {
        a{
            background:$color1;
            border:1px solid $color1;
            &:after{border-color:$body-bg;}
        }
    }
}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    .page-tp01, .page-tp02, .page-tp03, .page-tp04{
        padding:50px 0 20px;
        a:hover{@include opacity(1);}
        .prev, .next{
            a:hover{
                background:none;
                &:after{border-color:$color1;}
            }
        }
    }
    .page-tp01{
        li{padding:0 15px;}
        a:hover{
            background:$color1;
            color:#fff;
        }
    }
    .page-tp02, .page-tp03, .page-tp04{
        a:hover{color:$color1;}
    }
    .page-tp02{
        li {padding:0 5px;}
        a {
            @include font-size(17);
            width:40px; height:54px;
            line-height:54px;
            &:hover{border-bottom:3px solid $color1;}
        }
        .prev, .next{
            a:hover{border-bottom:3px solid $body-bg;}
        }
    }
    .page-tp03, .page-tp04{
        li{padding:5px 10px;}
        a{
            &:hover{text-decoration:underline;}
        }
        .prev, .next{
            a:hover{border:1px solid $color1;}
        }
    }
}
@media screen and (min-width: 1200px) {}