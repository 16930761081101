.path {
    font-size:0;
    .inner-wrap{margin:0 auto;}
    li{
        display:inline-block;
        vertical-align:middle;
        position:relative;
        @include font-size(12);
        &:after{
            content:'';
            display:inline-block;
            vertical-align:middle;
        }
        &:last-child:after{display:none;}
    }
    a {
        display:inline-block;
        vertical-align:middle;
        @include opacity(0.5);
    }
}

.path-tp01, .path-tp03, .path-tp04{padding:15px 15px 0;}
.path-tp01{
    .inner-wrap{max-width:1350px;}
    li{
        padding:4px 0;
        color:$color1;
        &:after{
            width:7px; height:7px;
            border-width:2px;
            border-color:#bbb;
            @extend %fake-arrow;
            @extend %rotate-right;
            margin:0 10px 2px 7px;
        }
    }
    a {color:$color-base;}
}
.path-tp02{
    padding:5px 10px;
    border-top:1px solid rgba(255,255,255,0.2);
    position:absolute;
    bottom:0; left:0;
    width:100%;
    z-index:3;
    .inner-wrap{max-width:1130px;}
    li{
        padding:4px 0;
        color:#fff;
        &:after{
            width:0; height:0;
            border-style:solid;
            border-width:2px 0 2px 6px;
            border-color:transparent transparent transparent #fff;
            margin:0 10px;
            @include opacity(0.5);
        }
        &:first-child a{
            font-size:0;
            text-indent:-9999px;
            display:inline-block;
            width:16px; height:14px;
            background:url(#{$img}path02-icon.png) center no-repeat;
            background-size:contain !important;
        }
    }
}
.path-tp03{
    .inner-wrap{max-width:1240px;}
    li{
        padding:4px 0 4px 10px;
        color:#999;
        &:after{
            content:"/";
            @include font-size(14);
            color:#eee;
            margin:0 0 0 10px;
        }
        &:first-child{
            padding:5px 0;
            a:before{
                @extend %btn-icon;
                width:16px; height:14px;
                background:url(#{$img}path03-icon.png) center no-repeat;
                background-size:contain !important;
                margin:-4px 6px 0 0;
            }
        } 
    }
    a{color:$color1;}
}
.path-tp04{
    .inner-wrap{max-width:1240px;}
    li{
        &:after{
            content:'>>';
            font-family:Arial, $font-ms;
            @include font-size(14);
            font-weight:700;
            color:$color1;
            display:inline-block;
            vertical-align:middle;
            padding:1px 10px 0;
            transform:scale(0.6,1);
        }
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    .path-tp01{
        padding:25px 20px;
        li:after{margin:0 25px 2px 22px;}
    }
}
@media screen and (min-width: 769px) {
    .path{
        a{
            @include font-size(14);
            &:hover{@include opacity(1);}
        }
    }
    .banner-tp04{
        &+.path{margin-top:-30px;}
    }

    .path-tp01, .path-tp03, .path-tp04{padding:20px 15px;}
    .path-tp01{
        a:hover{color:$color1;}
    }
    .path-tp03, .path-tp04{
        a:hover{text-decoration:underline;}
    }

    .path-tp02{
        padding:15px;
        a:hover{color:$color1;}
    }
}
@media screen and (min-width: 1200px) {}
@media screen and (min-width: 1280px) {}