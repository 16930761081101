.header-tp08{
    width:100%; min-width:320px;
    background:$color2;
	position: fixed;
    top:0;
    width:100%;
    min-width:320px;
    z-index:999;
    transition: .3s;
    &.fix{position: fixed; top:0;}
    .inner-wrap{
        transition: .3s;
        max-width:1440px;
        margin:0 auto;
        padding:10px;
    }
}
.mobile-side{
    width:100%;
    overflow-y:auto;
    position:absolute;
    top:100%;
    left:-100%;
    transition: .3s;
    &.on { left:0; }
}
#logo{
    display: block;
    width: 250px;
    transition: .3s;
    a, img { display: block; }
}
#nav-btn {
    display:block;
    font-size:0;
    text-indent:-9999px;
    width:30px; height:30px;
    background:$color1;
    position:absolute;
    top:50%; right:10px;
    transform: translate(0,-50%);
    overflow:hidden;
    z-index:2;
    .thumb {
        display:block;
        width:60%; height:2px;
        background:#fff;
        position:absolute;
        left:50%;
        transform-origin:center;
        transform: translate(-50%,0);
        transition: .3s;
        &.thumb-1 { top:8px; }
        &.thumb-2 { top:50%; margin-top:-1px; }
        &.thumb-3 { bottom:8px; }
    }
    &.on {
        .thumb-1 {
            transform:rotate(-45deg) translate(-50%,-50%);
            margin:0 0 0 -2px;
        }
        .thumb-2 { 
            @include opacity(0);
            visibility:hidden;
        }
        .thumb-3 {
            transform:rotate(45deg) translate(-50%,-50%);
            margin:0 0 -1px -3px;
        }
    }
}
#main-nav{
    background:$gray01;
    color:$body-bg;
    z-index:10;
    li{position:relative;}
    a { 
        @extend %font-size14-15;
    }
    &.on { left:0; }
    >ul>li{
        border-top:1px solid $body-bg;
        text-align:center;
        color:$color-base;
        >a{padding:12px;}
        &.on{
            >a{
                font-weight:bold;
                background:$body-bg;
                color:$color1;
                &:after{transform:rotate(135deg);}
            }                
            .sub-nav{display:block;}
        }
    }
    .has-nav >a:after{
        @extend %fake-arrow;
        @extend %rotate-right;
        display:inline-block;
        vertical-align:middle;
        width: 5px; height: 5px;
        border-width: 2px;
        border-color: $color1;
        margin:-2px 0 0 15px;
        transition: .3s;
    }
    .sub-nav{
        display:none;
        background:$body-bg;
        padding:0 0 10px;
        transition: .3s;
        li{border-top:1px solid $gray01;}
        a{padding:9px 12px;}
    }
}
.header-side{
    background:$body-bg;
    text-align:center;
    padding:12px 5px;
    ul, li{margin: 0; padding: 0; list-style: none outside none;}
    li{
        display:inline-block;
        vertical-align:middle;
        &:after{
            content:'|';
            display:inline-block;
            vertical-align:baseline;
            color:$gray02;
            padding:0 9px 0 6px;
        }
        &:last-child:after{display: none;}
    }
    .figure{
        display: block;
        &:after{display: none;}
    }
    a{
        @include font-size(12);
        color:$color-light;
        @include opacity(0.7);
    }
}
@media screen and (min-width: 375px) {
    .header-side{
        li:before{padding: 0 13px 0 10px;}
    }
}
@media screen and (min-width: 769px) {
    .header-tp08{
        .inner-wrap{padding:12px;}
    }
    .mobile-side{
        position:static;
        left:0; top:0;
        width:auto;
        overflow-y:visible;
    }
    #logo{
        float:left;
        width:350px;
    }
    #nav-btn { display:none; }
    #main-nav{
        clear: both;
        top:0;
        background:none;
        position:relative;
        text-align: center;
        padding:20px 0 3px;
        a{
            color:#fff;
            font-weight:bold;
            &:hover{transform:translate(0,-3px);}
        }
        >ul{
            margin: 0 -5px;
            >li{
                display:inline-block;
                vertical-align:top;
                border:0 none;
                padding:0 15px;
                >a{padding:0 5px 5px;}
                &:hover .sub-nav{display:block;}
                &.on{
                    .sub-nav{display:none;}
                    &:hover .sub-nav{display:block;}
                }
            }
        }
        .has-nav >a:after{display:none;}
        .sub-nav{
            position:absolute;
            top:100%;
            left:50%;
            transform: translate(-50%,0);
            background:$gray01;
            li{border-top: 1px solid $body-bg;}
            a{
                white-space:nowrap;
                padding:10px 20px;
                &:hover{
                    background:$color1;
                    color: #fff;
                }
            }
        }
    }
    .header-side{
        text-align:right;
        margin:-8px 0 0;
        padding:0;
        background:none;
        li:after{color:rgba(255,255,225,0.5);}
        .figure{
            text-align: right;
            a{
                display: inline-block;
                vertical-align: middle;
            }
        }
        a{
            color:#fff;
            @include opacity(1);
            &:hover{text-decoration:underline;}
        }
    }
}
@media screen and (min-width: 1024px) {
    .mobile-side{
        margin:0 0 0 250px;
        text-align:right;
    }
    #main-nav{
        clear: none;
        display:inline-block;
        vertical-align:middle;
        >ul>li{padding: 0 0 0 15px;}
    }
    .header-side{
        .figure{
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 -2px;
            &:after{display:inline-block;}
        }
    }
}
@media screen and (min-width: 1200px) {
    #main-nav{
        >ul>li{padding: 0 0 0 30px;}
    }
}

