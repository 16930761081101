/** social */
.social-wrap {
    font-size:0;
    ul, li { margin:0; padding:0; list-style:none outside none; }
    li{
        display:inline-block;
        vertical-align:middle;
        text-align:center;
    }
    li, a{
        position:relative;
        overflow:hidden;
    }
    a{font-size:0;}
}
.social-tp01, .social-tp02, .social-tp03, .social-tp04, .social-tp05, .social-tp06{
    li{
        display:inline-block;
        vertical-align:middle;
        padding:2px 10px;
    }
    a{display:block;}
}
.social-tp01, .social-tp02, .social-tp03, .social-tp04{
    a{
        width:25px; height:25px;
        background-size:contain!important;
    }
}

//icon's color black, no bg
.social-tp01 {
    .icon-facebook{background:inline-image('icon-fb-b.svg') center no-repeat;}
    .icon-instagram{background:inline-image('icon-ig-b.svg') center no-repeat;}
    .icon-line{background:inline-image('icon-line-b.svg') center no-repeat;}
    .icon-youtube{background:inline-image('icon-yt-b.svg') center no-repeat;}
    .icon-sina{background:inline-image('icon-sn-b.svg') center no-repeat;}
    .icon-wechat{background:inline-image('icon-wc-b.svg') center no-repeat;}
    .icon-qq{background:inline-image('icon-qq-b.svg') center no-repeat;}
    .icon-google-plus{background:inline-image('icon-google-b.svg') center no-repeat;}
    .icon-in{background:inline-image('icon-in-b.svg') center no-repeat;}
    .icon-skype{background:inline-image('icon-sk-b.svg') center no-repeat;}
    .icon-twitter{background:inline-image('icon-tw-b.svg') center no-repeat;}
}

//icon's color white, no bg
.social-tp02, .social-tp05 {
    .icon-facebook{background:inline-image('icon-fb-w.svg') center no-repeat;}
    .icon-instagram{background:inline-image('icon-ig-w.svg') center no-repeat;}
    .icon-line{background:inline-image('icon-line-w.svg') center no-repeat;}
    .icon-youtube{background:inline-image('icon-yt-w.svg') center no-repeat;}
    .icon-sina{background:inline-image('icon-sn-w.svg') center no-repeat;}
    .icon-wechat{background:inline-image('icon-wc-w.svg') center no-repeat;}
    .icon-qq{background:inline-image('icon-qq-w.svg') center no-repeat;}
    .icon-google-plus{background:inline-image('icon-google-w.svg') center no-repeat;}
    .icon-in{background:inline-image('icon-in-w.svg') center no-repeat;}
    .icon-skype{background:inline-image('icon-sk-w.svg') center no-repeat;}
    .icon-twitter{background:inline-image('icon-tw-w.svg') center no-repeat;}
}

//icon's color original, no bg
.social-tp03, .social-tp04 {
    .icon-facebook{background:inline-image('icon-fb.svg') center no-repeat;}
    .icon-instagram{background:inline-image('icon-ig.svg') center no-repeat;}
    .icon-line{background:inline-image('icon-line.svg') center no-repeat;}
    .icon-youtube{background:inline-image('icon-yt.svg') center no-repeat;}
    .icon-sina{background:inline-image('icon-sn.svg') center no-repeat;}
    .icon-wechat{background:inline-image('icon-wc.svg') center no-repeat;}
    .icon-qq{background:inline-image('icon-qq.svg') center no-repeat;}
    .icon-google-plus{background:inline-image('icon-google.svg') center no-repeat;}
    .icon-in{background:inline-image('icon-in.svg') center no-repeat;}
    .icon-skype{background:inline-image('icon-sk.svg') center no-repeat;}
    .icon-twitter{background:inline-image('icon-tw.svg') center no-repeat;}
}


//icon's color white, original bg 
.social-tp05, .social-tp06{
    a{
        width:30px; height:30px;
        background-size:70%!important;
        @include radius(50%);
        &:before, &:after{
            content:'';
            display:block;
            width:100%; height:100%;
            @include radius(50%);
            overflow:hidden;
            position:absolute;
            top:50%; left:50%;
            transform: translate(-50%,-50%);
            z-index:-1;
        }
        &:after{
            background:#000;
            @include opacity(0);
        }
    }
    .icon-facebook:before{background:#3B5998;}
    .icon-instagram:before{background:radial-gradient(circle at 30% 107%, #fdf497 0, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);}
    .icon-line:before{background:#00B900;}
    .icon-youtube:before{background:#EC162C;}
    .icon-sina:before{background:#E6162D;}
    .icon-wechat:before{background:#00C300;}
    .icon-qq:before{background:#000;}
    .icon-google-plus:before{background:#DC4E41;}
    .icon-skype:before{background:#01A7FF;}
    .icon-twitter:before{background:#1DA1F2;}
}

.social-tp06{
    svg{
        transform:scale(0.7);
        .icon{fill:#fff;}
    }
}

@media screen and (min-width: 769px) {
    .social-tp01, .social-tp02, .social-tp03, .social-tp04{
        a:hover{transform:translate(0,-2px);}
    }
    .social-tp01, .social-tp02, .social-tp04, .social-tp06 {
        a{
            @include opacity(0.3);
            &:hover{@include opacity(1);}
        }
    }
    .social-tp04{
        a{
            filter:saturate(0);
            &:hover{filter:saturate(1);}
        }
    }
    .social-tp05{
        a{
            &:after{@include opacity(0.3);}
            &:before{width:0; height:0;}
            &:hover{
                &:after{width:0; height:0;}
                &:before{width:100%; height:100%;}
            }
        }
    }
    .social-tp06{
        a{
            &:before{width:0; height:0;}
            &:hover{
                &:before{width:100%; height:100%;}
                svg .icon{fill:#fff;}
            }
        }
        svg .icon{fill:#000;}
    }
}
@media screen and (min-width: 1200px) {}