/** table-skin */
.tb-wrap { 
    padding:0 1px;
    overflow-x:auto;
    overflow-y: hidden;
    table{width:100%; border-collapse: collapse;}
    &.nowrap{
        th, td{
            white-space:nowrap;
            &.wrap{white-space:normal;}
        }
    }
    .center{text-align:center;}
    .left{text-align:left;}
    .right{text-align:right;}
}
.tb-js{
    table{border-top:1px solid $gray03;}
    thead, th {
        display:none;
        tr, th{display:none;}
    }
    tr{
        display:block;
        position:relative;
        border-bottom:1px solid $gray03;
        padding:5px 0;
    }
    td{
        display:block;
        text-align:left;
        padding:5px 10px;
        &:before {
            display:block;
            content:attr(data-txt);
            @include font-size(12);
            font-weight:bold;
            color:$color1;
            padding:2px 10px 0 0;
        }
    }
    &.tb-wrap .center{text-align:left;}
}

.tb01, .tb02, .tb03, .tb04, .tb05, .tb06, .tb07, .tb08{}

.tb01{
    text-align:left;
    th, td{padding:10px;}
    th{white-space:nowrap;}
    ul, li{margin: 0; padding: 0; list-style: none outside none;}
    a{color:$color1;}
}
.tb02{
    tr:nth-child(even){background:$gray01;}
    th,td{
        @extend %font-size14-15;
        border:1px solid $gray03;
        padding:10px;
    }
    th{
        background:$color1;
        color:#fff;
    }
    td:first-child{
        text-align:center;
        font-weight:bold;
    }
    a{color:$color1;}
}
.tb03{
    th,td{
        @extend %font-size14-16;
    }
    th{
        background:$color3;
        color:$body-bg;
    }
    td:before{color:$color2;}
    a{
        color:$color1;
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    .tb-js{
        padding:1px;
        table{border-top:none;}
        thead {
            display:table-header-group;
            tr{display:table-row;}
            th{display:table-cell;}
        }
        th{display:table-cell;}
        tr {
            display:table-row;
            position:static;
            margin:0; padding:0;
            border:0 none;
        }
        td {
            display:table-cell;
            &:before { content:none; display:none;}
        }
        &.tb-wrap .center{text-align:center;}
    }
    .tb03{
        th, td{
            border:1px solid $gray03;
            padding:10px;
        }
        tbody{
            tr:nth-child(even) td{background:$gray01;}
        }
    }
}
@media screen and (min-width: 769px) {
    .tb01, .tb02, .tb03, .tb04, .tb05, .tb06, .tb07, .tb08{
        a:hover{text-decoration:underline;}
    }
    .tb03{
        th, td{padding:10px 15px;}
        td{min-width:100px;}
        a:hover{text-decoration:underline;}
    }
}
@media screen and (min-width: 1200px) {}