#footer{
	position: relative;
    overflow:hidden;
    .inner-wrap{
        margin:0 auto;
        overflow:hidden;
    }
}
.footer-tp08{
    .social-link{
        a{
            display:block;
            font-size:0;
            background-size:contain !important;
        }
    }
    .footer-nav{
        font-size:0;
        overflow:hidden;
    }
    .design{
        @include font-size(12);
        a { 
            font-weight:bold;
            color:$color1;
        }
    }
}

//footer-nav right
.footer-tp08{
    background:url(#{$img}footer-bg.jpg) center no-repeat;
    background-size:cover;
    border-top:4px solid $color1;
    padding:10px 5% 20px;
    .inner-wrap{max-width:1400px;}
    .footer-nav{
        >ul>li{
            display:inline-block;
            vertical-align:middle;
            width:50%;
            >a{
                text-align:center;
                @extend %font-size14-16;
                font-weight:bold;
                color:$color1;
                padding:8px 0;
            }
        }
    }
    .corp-intro{
        padding:15px 0 0;
        ul, li{margin: 0; padding: 0; list-style: none outside none;}
        li{
            display:inline-block;
            vertical-align:middle;
            padding:3px 17px 3px 0;
        }
        .icon1, .icon2{
            &:before{
                content:'';
                display:inline-block;
                vertical-align:middle;
                width:16px; height:16px;
                background-size:contain!important;
                margin:0 5px 0 0;
            }
        }
        .icon1:before{background:url(#{$img}footer-tp08-icon01.svg) center no-repeat;}
        .icon2:before{background:url(#{$img}footer-tp08-icon02.svg) center no-repeat;}
    }
    .copyright{padding:10px 0 0;}
}

@media screen and (min-width: 769px) {
    .footer-tp08{
        padding:30px 15px;
        .footer-nav{
            float:right;
            >ul>li{
                width:auto;
                &, &+li:before, >a{
                    display:inline-block;
                    vertical-align:middle;
                }
                &+li:before{
                    content:'/';
                    @include font-size(16);
                    color:#bbb;
                    padding:0 15px;
                    line-height: 1;
                    margin: -3px 0 0;
                }
                >a{
                    padding:0;
                    &:hover{color:darken($color1,20%);}
                }
            }
        }
        .corp-intro{
            padding:0;
            a:hover{color:$color1;}
        }
    }
}
@media screen and (min-width: 1280px) {
    .footer-tp08{
        .footer-nav{
            >ul>li{
                &+li:before{padding:0 25px;}
            }
        }
    }
}