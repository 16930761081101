/** group */
.item-group {
    font-size:0;
    &, .item, .item-inner, .figure, .text{
        margin:0 auto;
        overflow:hidden;
        position:relative;
    }
    a.item-inner{display:block;}
    .figure img{transition: .3s;}
}

@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1280px) {}