.page00{}
.page01{}
.page02{
    .tp092{padding:10px 5% 50px;}
}
.page03{
    .tp094{
        >.model-inner{max-width:1120px;}
    }
    .editor{
        &, p{@extend %font-size14-17;}
        color:#777;
    }
    .list{
        padding:40px 0 0;
        li{color:#777;}
    }
}
.page04{
    .info-nav a{@extend %font-size14-17;}
    .tp067{
        >.model-inner{max-width:$inner-w1;}
    }
}
.page06{
   .tp029  >.model-inner{max-width:1500px;}
    .list{
        dt{color:#2683D3;}
        .col2{
            li, dd{
                display:inline-block;
                vertical-align:top;
                width:49.999%;
            }
        }
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    .page06{
        .list{
            .col2{
                li, dd{width:33.333%;}
            }
        }
    }
}
@media screen and (min-width: 769px) {
    .page02{
        .tp092{padding:20px 5% 150px;}
    }
    .page03{
        .tp094{padding-bottom:100px;}
        .list{
            a:hover{color:$color1;}
        }
    }
    .page04{
        .nav-switch ul{text-align:left;}
    }
    .page06{
        .tp029{padding-left:11%;}
        .list{
            dt{@include font-size(22);}
        }
    }
}
@media screen and (min-width: 1200px) {}