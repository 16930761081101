/** 圖片路徑 */
$path:'../';
$img:$path + 'images/';
$font:$path + 'font/';

/** 字型 */
$font-ms:"微軟正黑體修正", "微軟正黑體", "Microsoft JhengHei", "MHei", "STHeitiTC-Light", sans-serif;
$font-base:$font-ms, Arial, Helvetica, "Helvetica Neue", Tahoma, Verdana;
$font-comm:"CenturyGothicRegular", $font-base;
$font-light:'HelveticaNeueLTStd35', $font-base;
$font-bold:'CenturyGothicBold', $font-base;
$font-black:'HelveticaNeueLTStd85', $font-base;


/**same background setting*/
$mask-bg:#000;
$body-bg:#fff;

/** font-color */
$color-base:#333;
$color-light:#666;      //color-base's lighten
$input-color:#888;
$title-color:#000;

/**Gray scale*/
$gray01:#f5f5f5;        //light than $body-bg
$gray02:#eee;           //more light than $body-bg
$gray03:#ddd;


/**Theme color*/
$color1:#C0AE66;
$color2:#3A3C64;
$color3:#2C4558;

/**model-inner width*/
$inner-w1:1020px;