%font-size12{@include font-size(12);}
%font-size13{@include font-size(13);}
%font-size14, %font-size14-15, %font-size14-16, %font-size14-17, %font-size14-18{@include font-size(14);}
%font-size16-18{@include font-size(16);}
%fake-border {
    content:'';
    display:block;
    width:100%; height:100%;
    position:absolute;
    top:50%; left:50%;
    transform: translate(-50%,-50%);
    z-index:1;
    transition: .3s;
}
%fake-arrow {
    content:'';
    border-style:solid;
    transform: rotate(45deg);
    transition: .3s;
}
%rotate-top { border-right:0 none; border-bottom:0 none; }
%rotate-right { border-left:0 none; border-bottom:0 none; }
%rotate-bottom { border-left:0 none; border-top:0 none; }
%rotate-left { border-top:0 none; border-right:0 none; }
%swiper-button{
    .swiper-button-prev, .swiper-button-next{
        background:none;
        //width:45px; height:45px;
        //border:1px solid #fff;
        transform: rotate(45deg);
        transition: .3s;
        //margin:-23px 0 0;
    }
    .swiper-button-prev{
        border-top:0 none; border-right:0 none;
        //left:20px;
    }
    .swiper-button-next{
        border-left:0 none; border-bottom:0 none;
        //right:20px;
    }
}
%swiper-pagination{
    bottom:30px;
    .swiper-pagination-bullet{
        width:12px; height:12px;
        background:#aaa;
    }
    .swiper-pagination-bullet-active{background:#69B1E7;}
}
%banner-home {
    position:relative;
    font-size:0;
    @extend %swiper-button;
    @extend %swiper-pagination;
}
%banner {
    position:relative;
    margin:0 auto;
    overflow:hidden;
    .figure {
        min-height:100px;
        >img {
            display: block;
            margin: 0 auto;
            visibility: hidden;
        }
    }
    .text {
        display:none;
        text-align:center;
        color:#fff;
        position:absolute;
        bottom:25%; left:0;
        width:100%;
        padding:0 10px;
        z-index:1;
    }
    .title {
        @include font-size(50);
        font-weight:normal;
        position:relative;
    }
}
%btn-icon{
    content:'';
    display:inline-block;
    vertical-align:middle;
    background-size:contain !important;
    transition: .3s;
}
%drop-down{
    .label{
        //background:#555;
        //color:#fff;
        padding:20px 0;
        position:relative;
        &:after{
            @extend %fake-arrow;
            @extend %rotate-bottom;
            width:10px; height:10px;
            //border-width:1px;
            //border-color:#fff;
            position:absolute;
            //top:22px; right:20px;
        }
    }
    ul{display:none;}
    li.on{display:none;}
    a{
        //@include font-size(15);
        //color:#666;
        //padding:12px 0;
    }
    &.on{
        .label:after{
            //border:1px solid #fff;
            border-right:0 none; border-bottom:0 none;
            //top:28px;
        }
        ul{display:block;}
    }
}
%info-nav{
    padding:0 15px 20px;
    ul, li{margin: 0; padding: 0; list-style: none outside none;}
    li{
      display:inline-block;
      vertical-align:top;
      padding:0 5px;
    }
    a{
        background:#DEDFDF;
        @include font-size(14);
    }
    .on a{
        color:#000;
    }
}
%search-form {
    font-size:0;
    position:relative;
    margin:0 auto;
    text-align:left;
    .form-wrap {
        @include font-size(16);
        position:relative;
        max-width:360px;
        padding:0 60px 0 0;
        margin:0 auto;
        overflow:hidden;
        z-index:0;
    }
    .skin-wrap {
        background:#fff;
        overflow:hidden;
        &:before {
            content:''; display:block;
            width:16px; height:16px;
            background:url(#{$img}icon-search.png) center no-repeat;
            background-size:contain;
            position:absolute;
            left:15px; top:50%;
            margin:-8px 0 0;
            z-index:1;
        }
    }
    .keyword {
        position:relative;
        width:100%;
        padding:7px 5px 7px 37px;
        border:0 none;
        background:none;
        z-index:1;
    }
    .btn-search {
        position:absolute;
        right:0; top:0;
        padding:0 15px 0 12px;
        width:60px; height:100%;
        color:#fff;
        border:0 none;
        overflow:hidden;
    }
}

@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    %hover-pic{
        overflow:hidden;
        .figure{
            overflow:hidden;
            img{transition: .3s;}
        }
        &:hover{
            img {transform:scale(1.1);}
        }
    }
    %swiper-button{
        .swiper-button-prev, .swiper-button-next{
            &:hover{opacity:1;}
        }
    }
    %drop-down{
        .label{display:none;}
        ul{display:block;}
        li{
            width:auto;
            &.on{display:inline-block;}
        }
    }
}
@media screen and (min-width: 1280px) {
    %font-size14-15{@include font-size(15);}
    %font-size14-16{@include font-size(16);}
    %font-size14-17{@include font-size(17);}
    %font-size14-18{@include font-size(18);}
    %font-size16-17{@include font-size(17);}
    %font-size16-18{@include font-size(18);}
}
@media screen and (min-width: 1500px) {}