/* 修正粗體 */
@font-face {
    font-family: '微軟正黑體修正';
    unicode-range: U+7db0, U+78A7, U+7B75; /* ASCII (碧筵綰) */
    font-style: normal;
    font-weight: bold;
    src: local(Yu Gothic), local(MS Gothic);
}
/* 一般粗細的時候改回微軟正黑 */
@font-face {
    font-family: '微軟正黑體修正';
    unicode-range: U+7db0, U+78A7, U+7B75; /* ASCII (碧筵綰) */
    font-style: normal;
    font-weight: normal;
    src: local(微軟正黑體);
}
@font-face {
    font-family: 'CenturyGothicRegular';
    src: url('#{$font}CenturyGothic/CenturyGothicRegular.eot');
    src: url('#{$font}CenturyGothic/CenturyGothicRegular.eot?#iefix') format('embedded-opentype'),
         url('#{$font}CenturyGothic/CenturyGothicRegular.woff2') format('woff2'),
         url('#{$font}CenturyGothic/CenturyGothicRegular.woff') format('woff'),
         url('#{$font}CenturyGothic/CenturyGothicRegular.ttf') format('truetype'),
         url('#{$font}CenturyGothic/CenturyGothicRegular.svg#CenturyGothicRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CenturyGothicBold';
    src: url('#{$font}CenturyGothic/CenturyGothicBold.eot');
    src: url('#{$font}CenturyGothic/CenturyGothicBold.eot?#iefix') format('embedded-opentype'),
         url('#{$font}CenturyGothic/CenturyGothicBold.woff2') format('woff2'),
         url('#{$font}CenturyGothic/CenturyGothicBold.woff') format('woff'),
         url('#{$font}CenturyGothic/CenturyGothicBold.ttf') format('truetype'),
         url('#{$font}CenturyGothic/CenturyGothicBold.svg#CenturyGothicBold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaNeueLTStd35';
    src: url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd35.eot');
    src: url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd35.eot?#iefix') format('embedded-opentype'),
         url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd35.woff2') format('woff2'),
         url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd35.woff') format('woff'),
         url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd35.ttf') format('truetype'),
         url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd35.svg#HelveticaNeueLTStd35') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaNeueLTStd85';
    src: url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd85.eot');
    src: url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd85.eot?#iefix') format('embedded-opentype'),
         url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd85.woff2') format('woff2'),
         url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd85.woff') format('woff'),
         url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd85.ttf') format('truetype'),
         url('#{$font}HelveticaNeueLTStd/HelveticaNeueLTStd85.svg#HelveticaNeueLTStd85') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvantGardeMdBTMedium';
    src: url('#{$font}AvantGardeMdBT/AvantGardeMdBTMedium.eot');
    src: url('#{$font}AvantGardeMdBT/AvantGardeMdBTMedium.eot?#iefix') format('embedded-opentype'),
         url('#{$font}AvantGardeMdBT/AvantGardeMdBTMedium.woff2') format('woff2'),
         url('#{$font}AvantGardeMdBT/AvantGardeMdBTMedium.woff') format('woff'),
         url('#{$font}AvantGardeMdBT/AvantGardeMdBTMedium.ttf') format('truetype'),
         url('#{$font}AvantGardeMdBT/AvantGardeMdBTMedium.svg#AvantGardeMdBTMedium') format('svg');
    font-weight: normal;
    font-style: normal;
}