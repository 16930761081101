::-webkit-input-placeholder { /* WebKit browsers */
    @include font-size(13);
    color:$input-color;
   opacity:  1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    @include font-size(15);
   color:$input-color;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    @include font-size(15);
   color:$input-color;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    @include font-size(15);
   color:$input-color;
   opacity:  1;
}
html {
	font-size:62.5%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
    height:100%;
}
body {
    background:$body-bg;
    @extend %font-size14-15;
    position:relative;
    font-family: $font-base;
    color:$color-base;
    line-height:1.5;
    height:100%;
    &.lock {
        overflow:hidden;
        -webkit-backface-visibility: hidden;
    }
}
*, *:before, *:after {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}
input, select, textarea{
    @include font-size(16);
    outline:0 none;
    padding:8px;
    &[type="checkbox"], &[type="radio"]{
        width:auto; height:auto;
        padding:0;
        vertical-align:middle;
        cursor:pointer; 
    }
}
select {cursor:pointer; vertical-align:middle;}
textarea {resize:none;}
h1, h2, h3, h4, h5, h6, p { margin:0;}
label{cursor:pointer;}
a, button{ 
    outline:0 none; text-decoration:none; color:inherit;
    &, &:before, &:after{transition: .3s;}
}

//template
nav, .path, .page-limit{
	ul, li {margin:0; padding: 0; list-style: none outside none;}
    ul{font-size:0;}
    a{display:block;}
}
.figure{
    img{display:block; margin:0 auto;}
    &.web { display:none; }
}
.tab-detail{position:relative;}
.tab-item{
    display:none;
    &.on{display:block;}
}


//wrap
#container {
	position: relative;
    min-width: 320px;
    margin:0 auto;
    overflow:hidden;
    padding:62px 0 0;
}
#content {
    margin:0 auto;
    clear:both;
    position:relative;
    overflow:hidden;
}
#mask-bg {
    @include opacity(0);
    position:fixed;
    left:0; top:0;
    width:100%; height:0;
    background:$mask-bg;
    overflow:hidden;
    z-index:998;
    -moz-transition: opacity .3s;
    -o-transition: opacity .3s;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    &.on {
        @include opacity(.8);
        height:100%;
    }
}

@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {
    //template
    .figure{
        &.mobile { display:none; }
        &.web { display:block; }
    }
}
@media screen and (min-width: 769px) {
    body{
        &.lock {
            overflow-y:auto;
            -webkit-backface-visibility: visible;
        }
    }
    //wrap
    #container {
        min-width:1024px;
        padding:87px 0 0;
    }
    #mask-bg { 
        display:none;
        &.always{display:block;}
    }
}
@media screen and (min-width: 1200px) {}
@media screen and (min-width: 1280px) {}