.btn-wrap {
    font-size:0;
    .btn {
        display:inline-block;
        vertical-align:middle;
    }
}
.btn {
    display:inline-block;
    margin:0 auto;
    text-align:center;
    overflow:hidden;
    transition: .3s;
    &:before, &:after { transition: .3s; }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1200px) {}