/** table-skin */
.editor{
    @include font-size(14);
    color:#444;
    line-height:1.5;
    img{height:auto !important;}
    h1, h2, h3, h4, h5, h6{line-height:1;}
    h3{
        @include font-size(19);
        color:#000;
        padding:0 0 10px;
    }
    h4{
        @include font-size(15);
        padding:0 0 10px;
    }
    p{padding:0 0 5px;}
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {}
@media screen and (min-width: 1200px) {}