/** Template*/
.model{
    font-size:0;
    overflow:hidden;
    .model-inner{
        margin:0 auto;
        overflow:hidden;
        position:relative;
    }
    .figure, .text{position:relative;}
    h2, h3, h4, h5, h6{font-family:$font-comm;}
    .text, p{
        @extend %font-size14-15;
        line-height:1.5;
    }
    p{padding:0 0 10px;}
    .head, .info-title{
        text-align:center;
        padding:0 3% 10px;
        color:$title-color;
        overflow:hidden;
        position:relative;
        &, h2, h3, span, small{line-height:1.2;}
        small{
            display: block;
            font-family:'AvantGardeMdBTMedium', $font-base;
            @include font-size(12);
            font-weight:bold;
            padding:10px 0 0;
            @include opacity(0.8);
        }
    }
    .head h2, .info-title{
        @include font-size(28);
        position:relative;
    }
    .btn{
        @include font-size(15);
        font-weight:bold;
    }
    .info-nav{padding-top:10px;}
}
.model-border{
    width:100%; height:1px;
    background:$gray02;
}
.narrow-tp01{
    background:$body-bg;
    max-width:1280px;
    margin:0 auto;
    overflow:hidden;
}

.tp029{
    .model-inner{
        max-width:1200px;
        padding:40px 5%;
    }
    .head+.list, .editor+.list{padding:20px 0 0;}
    .list{
        dt{color:$color1;}
        .date{
            display:inline-block;
            vertical-align:top;
            background:$gray01;
            @include font-size(12);
            font-weight:normal;
            color:#999;
            @include radius(3px);
            padding:2px 5px;
            margin:0 10px;
        }
    }
    h3{
        @include font-size(28);
        padding:0 0 10px;
    }
    p{
        line-height:1.8;
        a{color:$color1;}
    }
}
.tp067, .tp085{padding:40px 15px 50px;}
.tp067{
    >.model-inner{max-width:1200px;}
    p{line-height:2;}
    table{width:100%; border-collapse: collapse;}
    th, td{padding:5px;}
    th{
        @extend %font-size14-17;
        background:$color3;
        color:$body-bg;
        white-space:nowrap;
    }
    td{
        @extend %font-size14-18;
        &:last-child{
            max-width: 230px;
            &:before{display:none;}
        }
    }
    .date{
        text-align:center;
        font-family:'AvantGardeMdBTMedium', $font-base;
        @include font-size(14);
        font-weight:bold;
    }
    .tag{font-weight:bold;}
    .tag1{color:$color2;}
    .tag2, .tag4{@include opacity(0.3);}
    .tag3{color:$title-color;}
    .btn-box{
        position:absolute;
        bottom:22px; right:5%;
        text-align: right;
    }
    .btn{
        font-size:0;
        text-indent:-9999px;
        width:30px; height:30px;
        background:url(#{$img}tp067-btn.svg) center no-repeat;
        background-size:contain!important;
    }
    .btn01{background:url(#{$img}tp067-btn1.png) center no-repeat;}
    .btn02{background:url(#{$img}tp067-btn2.png) center no-repeat;}
    .btn03{background:url(#{$img}tp067-btn3.png) center no-repeat;}
    .btn04{background:url(#{$img}tp067-btn4.png) center no-repeat;}
    .btn05{background:url(#{$img}tp067-btn5.png) center no-repeat;}
}
.tp085{
    .model-inner{max-width:1040px;}
    .item{
        border-bottom:1px dashed #ddd;
        padding:10px 5px;
        &.on{
            .q-wrap{
                .more{display:none;}
                &:after{background:url(#{$img}tp085-icon03.svg) left center no-repeat;}
            }
            .a-wrap{
                height:auto;
                @include opacity(1);
                padding:10px 0 0;
            }
        }
    }
    h3{
        @extend %font-size14-16;
        max-width:900px;
        padding:5px 0;
    }
    .date, .more{
        display:block;
        font-family:$font-bold;
        @include font-size(12);
        white-space:nowrap;
        transition: .3s;
        &:before, &:after{
            display:inline-block;
            vertical-align:middle;
            height:2px;
            transition: .3s;
        }
    }
    .date{
        color:#999;
        &:after{
            content:'';
            width:24px;
            background:#ddd;
            margin:-2px 0 0 10px;
        }
    }
    .more{
        text-align:right;
        color:$color2;
        &:before{
            content:'';
            width:85px;
            background:$color2;
            margin:-2px 10px 0 0;
        }
    }
    .btn-wrap{
        padding:30px 0 10px;
        text-align:center;
    }
    .btn{
        font-family:$font-bold;
        &:before, &:after{
            content:'';
            display:inline-block;
            vertical-align:middle;
            width:70px; height:5px;
            background:#333;
            margin:-2px 15px 0;
        }
    }
    .q-wrap{
        display:block;
        padding-right:30px;
        position:relative;
        &:after{
            content:'';
            display:block;
            width:34px; height:34px;
            background:url(#{$img}tp085-icon02.svg) left center no-repeat;
            background-size:contain!important;
            border:1px solid $gray02;
            @include radius(3px);
            overflow:hidden;
            position:absolute;
            top:50%; right:10px;
            transform: translate(0,-50%);
            transition: .3s;
        }
    }
    .a-wrap{
        @include opacity(0);
        height:0;
        overflow:hidden;
        transition: .3s;
    }
    .link-wrap{
        padding:15px 0 0;
        ul, li{margin: 0; padding: 0; list-style: none outside none;}
        li{
            display:inline-block;
            vertical-align:middle;
            padding:6px 40px 6px 0;
        }
        a{
            @extend %font-size14-17;
            font-weight:bold;
            color:$color1;
            padding:3px 0 3px 25px;
            background:url(#{$img}tp085-icon01.svg) left center no-repeat;
            background-size:18px;
            filter:saturate(0);
        }
    }
}
.tp089{
    .item-group, .item{padding:3px 0;}
    .item-inner{
        max-width:480px;
        position:relative;
        text-align:center;
    }
    .title{
        @include font-size(18);
        font-weight:normal;
    }
    .figure{
        &:before{
            @include fake-block();
            height:0;
            background:linear-gradient(to bottom, $color1 0, $title-color 100%);
            @include opacity(0);
        }
    }
    .text{
        position:absolute;
        width:100%;
        padding:10px;
        z-index:2;
        padding:10px 12%;
        top:50%; left:50%;
        transform: translate(-50%,-50%);
        color:$title-color;
        transition: .3s;
        &:after{
            content:'VIEW MORE';
            display:block;
            @include font-size(12);
            color:$body-bg;
            height:0; 
            overflow:hidden;
            transition: .3s;
        }
    }
}
.tp092, .tp094{padding:40px 5% 50px;}
.tp092{
    >.model-inner{max-width:1040px;}
    .head{
        small{
            color:$input-color;
            padding:0 0 10px;
        }
        h2{
            font-weight:normal;
            color:$color2;
        }
    }
    .item-group{padding:20px 0 0;}
    .item-inner{
        border-bottom: 1px dashed $gray03;
        padding:10px 30px 10px 5px;
        &:first-child{border-top: 1px dashed $gray03;}
        &:after{
            content:'';
            display:block;
            width:20px; height:20px;
            background:url(../images/tp092-icon01.svg) center no-repeat;
            background-size:contain!important;
            position:absolute;
            top:50%; right:10px;
            transform: translate(0,-50%);
        }
    }
    .date, .text{transition: .3s;}
    .date{
        font-family:'AvantGardeMdBTMedium', $font-base;
        @include font-size(14);
        font-weight:bold;
        color:$input-color;
    }
    .text{
        @extend %font-size16-18;
        color:$title-color;
    }
    .btn-wrap{
        text-align:center;
        padding:30px 0 0;
    }
    .btn{
        @include font-size(18);
        font-weight:normal;
        color:$color2;
        &:before, &:after{
            content:'';
            display:inline-block;
            vertical-align:middle;
            width:80px; height:6px;
            background:$color2;
            margin:-2px 10px 0;
        }
    }
}
.tp093{
    padding:15px 15px 50px;
    >.model-inner{max-width:$inner-w1;}
    .item{padding:50px 0 0;}
    .title{
        @include font-size(26);
        font-weight:normal;
        color:$color1;
        padding:0 0 10px;
    }
}
.tp094{
    >.model-inner{max-width:$inner-w1;}
    .head{
        text-align:left;
        margin:0 -3%;
        h2{
            @include font-size(18);
            font-weight:normal;
            padding:0 0 10px;
        }
        .date{
            font-family:'AvantGardeMdBTMedium', $font-base;
            @include font-size(16);
            color:$color1;
        }
    }
    .editor, .list, .btn-wrap{padding:30px 0 0;}
    .btn-wrap{text-align:center;}
    .btn{
        font-family:'AvantGardeMdBTMedium', $font-base;
        @include font-size(18);
        color:$color-light;
        &:before{
            content:'';
            display:inline-block;
            vertical-align:middle;
            width:40px; height:40px;
            background:url(#{$img}arr-39-prev.svg) center no-repeat, $color1;
            background-size:12px!important;
            margin:0 15px 0 0;
            overflow:hidden;
        }
    }
}

@media screen and (min-width: 480px) {
    .tp089{
        .item-group, .item{padding:3px;}
        .item{
            display:inline-block;
            vertical-align:top;
            width:49.999%;
        }
    }
}
@media screen and (min-width: 640px) {
    .model{
        .head{
            h2{@include font-size(32);}
            small{@include font-size(14);}
        }
    }
}
@media screen and (min-width: 768px) {
    .tp067{
        padding:45px 15px 100px;
        th{
            &+th{border-left:1px solid $color-light;}
            &:last-child{
                display:table-cell;
                border-right:none;
            }
        }
        td{
            border-bottom:2px dotted $gray03;
            &:first-child{width: 55%;}
            &:nth-last-child(1){
                padding-top:5px;
                padding-bottom:5px;
            }
            &:nth-last-child(1), &:nth-last-child(2){text-align:center;}
            a:hover{color:$color1;}
        }
        .btn-box{
            position:relative;
            bottom:auto; right:auto;
            text-align: center;
        }
        .btn{width:40px; height:40px;}
    }
    .tp085{
        .item{padding:20px 10px;}
        .date, h3, .more{
            display:table-cell;
            vertical-align:middle;
        }
        .date{width:125px;}
        .more{padding-left:25px;}
        .btn-wrap{padding:50px 0 10px;}
    }
    .tp085, .tp092{padding:60px 15px;}
    .tp093{
        padding:40px 15px 120px;
        .item{padding:60px 0 0;}
    }
}
@media screen and (min-width: 769px) {
    .model{
        .head, .info-title{padding:0 15px 15px;}
        .head h2, .info-title{@include font-size(36);}
    }
    .tp029{
        .list dt{@include font-size(20);}
        a:hover{
            color:$color1;
            text-decoration:underline;
        }
    }
    .tp067{
        tr:hover td{background:$gray01;}
        td{padding:20px;}
    }
    .tp085{
        .item{padding:30px 10px;}
        .item-inner, .q-wrap{
            &:hover{
                .date{
                    color:#000;
                    &:after{background:#000;}
                }
                p{
                    font-weight:bold;
                    color:$color2;
                }
                .more{
                    color:#999;
                    &:before{
                        width:24px;
                        background:#ddd;
                    }
                }
            }
        }
        .btn{
            @include font-size(18);
            &:hover{
                color:$color1;
                &:before, &:after{
                    width:200px;
                    background:$color1;
                }
            }
        }
        .q-wrap{padding-right:60px;}
        .link-wrap{
            a:hover{
                filter:saturate(1);
                text-decoration:underline;
            }
        }
    }
    .tp089{
        .item{width:24.999%;}
        .item-inner:hover{
            .figure:before{
                height:100%;
                @include opacity(0.7);
            }
            .text{
                color:$body-bg;
                &:after{ 
                    display:inline-block;
                    padding:10px 20px;
                    border:1px solid $body-bg;
                    height:auto;
                    margin:20px auto 0; 
                }
            }
        }
    }
    .tp092{
        padding:70px 5% 90px;
        .item-group{padding:30px 0 0;}
        .item-inner{
            padding:30px 110px 30px 3%;
            &:after{
                width:40px; height:40px;
                right:55px;
            }
            &:hover{
                background:$gray01;
                &:after{right:30px;}
                .date{color:$title-color;}
                .text{
                    font-weight:bold;
                    color:$color1;
                }
            }
        }
        .date, .text{
            display:inline-block;
            vertical-align:middle;
        }
        .date{width:110px;}
        .btn-wrap{padding:70px 0 0;}
        .btn{
            &:before, &:after{
                width:140px;
                margin:-3px 20px 0;
            }
            &:hover{
                filter:brightness(.5);
                &:before, &:after{width:80px;}
            }
        }
    }
    .tp094{
        .head{
            margin:0 -15px;
            h2{@include font-size(26);}
        }
        .btn:hover{
            color:$color1;
            &:before{@include radius(50%);}
        }
    }
}
@media screen and (min-width: 1200px) {
    .model{

    }
}
@media screen and (min-width: 1400px) {
    .tp089{
        .item-group{
            .title{@include font-size(24);}
        }
    }
}
