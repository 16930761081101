.list{
    dl, dt, dd, ul, ol, li{margin: 0; padding: 0; list-style: none outside none;}
    ul, ol, dl{font-size:0;}
    li, dd{
        position:relative;
        overflow:hidden;
        @extend %font-size14-17;
        counter-increment: number;
    }
}
.list-tp00, .list-tp01, .list-tp02, .list-tp03, .list-tp04, .list-tp05, .list-tp06, .list-tp07, .list-tp08, .list-tp09{
    ul, dl{padding:0 0 30px;}
    li, dt, dd{text-align:left;}
    dt{
       @extend %font-size16-18;
        font-weight:bold;
        color:$title-color;
        padding:0 0 10px;
    }
    li, dd{padding:0 0 10px;}
}
.list-tp02, .list-tp03, .list-tp04, .list-tp05, .list-tp06, .list-tp07, .list-tp08{
    li, dd{
        padding-left:20px;
        &:before{
            content:'';
            display:block;
            width:12px; height:12px;
            background-size:contain!important;
            position:absolute;
            top:5px; left:0;
        }
    }
}
.list-tp01{
    li, dd{
        &:before{
            color:#bbb;
            margin:0 5px 0 0;
        }
        &:nth-child(-1n+9):before{content: "0" counter(number)"." ;}
        &:nth-child(n+10):before{content: counter(number)"." ;}
    }
}
.list-tp02{ dd,li{&:before{background:url(#{$img}list-tp02.png) center no-repeat;}}}
.list-tp03{ dd,li{&:before{background:url(#{$img}list-tp03.png) center no-repeat;}}}
.list-tp04{ dd,li{&:before{background:url(#{$img}list-tp04.png) center no-repeat;}}}
.list-tp05{ dd,li{&:before{background:url(#{$img}list-tp05.png) center no-repeat;}}}
.list-tp06{ dd,li{&:before{background:url(#{$img}list-tp06.png) center no-repeat;}}}
.list-tp07{ dd,li{&:before{background:url(#{$img}list-tp07.png) center no-repeat;}}}
.list-tp08{ dd,li{&:before{background:url(#{$img}list-tp08.png) center no-repeat;}}}
.list-tp09{
    a{
        display:inline-block;
        padding-left:30px;
        position:relative;
        &:before{
            content:'';
            display:block;
            width:22px; height:22px;
            background:url(#{$img}list-tp09.svg) center no-repeat;
            background-size:contain!important;
            position:absolute;
            top:50%; left:0;
            transform: translate(0,-50%);
        }
    }
}

.list-tp11{
    li{padding:8px 80px 8px 0;}
    .col1, .col2{
        display:table-cell;
        vertical-align:middle;
        white-space:nowrap;
        line-height:1;
        overflow:hidden;
    }
    .col1{
        @include font-size(20);
        padding:0 15px 0 0;
    }
    .col2{
        width:100%;
        text-align:right;
        font-family:'BellCentennialSubCaption', $font-base;
        @include font-size(24);
        position:relative;
        &:before{
            content:'';
            display:block;
            width:100%; height:0;
            border-bottom:2px dotted #E7D5BB;
            position:absolute;
            top:50%; right:35px;
            transform: translate(0,-50%);
        }
    }
    .col3{
        width:70px;
        position:absolute;
        top:50%; right:0;
        transform: translate(0,-50%);
        text-align:left;
    }
    .icon1, .icon2{
        display:inline-block;
        vertical-align:middle;
        font-size:0;
        width:30px; height:30px;
        background-size:contain!important;
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {
    .list{
        &.col2, &.col3, &.col4, &.col5, &.flex2, &.flex3, &.flex4, &.flex5{
            ul, dl{padding:0 20px 40px 20px;}
        }
        &.col2, &.col3, &.col4, &.col5{
            ul, dl{
                display:inline-block;
                vertical-align:top;
                width:49.999%;
            }
        }
        &.flex2, &.flex3, &.flex4, &.flex5{
            display:flex;
            flex-flow:row wrap;
            justify-content:flex-start;
            ul, dl{flex: 1 0 49.9999%;}
        }
        .col2, .col3, .col4, .col5, .flex2, .flex3, .flex4, .flex5{
            li, dd{padding:0 20px 15px 20px;}
        }
        .col2, .col3, .col4, .col5{
            li, dd{
                display:inline-block;
                vertical-align:top;
                width:49.999%;
            }
        }
        .flex2, .flex3, .flex4, .flex5{
            display:flex;
            flex-flow:row wrap;
            justify-content:flex-start;
            dt{width:100%;}
            li, dd{flex: 1 0 49.9999%;}
        }
    }

}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    .list{
        &.col3, &.col4, &.col5{
            ul, dl{width:33.333%;}
        }
        &.flex3, &.flex4, &.flex5{
            ul, dl{flex:1 0 33.333%;}
        }
        .col3, .col4, .col5{
            li, dd{width:33.333%;}
        }
        .flex3, .flex4, .flex5{
            li, dd{flex:1 0 33.333%;}
        }
    }
    .list-tp09{
        a{
            &:before{filter:saturate(0);}
            &:hover{
                color:$color1;
                &:before{filter:saturate(1);}
            }
        }
    }
}
@media screen and (min-width: 1280px) {
    .list{
        &.col4, &.col5{
            ul, dl{width:24.999%;}
        }
         &.flex4, &.flex5{
            ul, dl{flex:1 0 24.999%;}
        }
        .col4, .col5{
            li, dd{width:24.999%;}
        }
        .flex4, .flex5{
            li, dd{flex:1 0 24.999%;}
        }
    }
}
@media screen and (min-width: 1400px) {
    .list{
        &.col5{
            ul, dl{width:19.999%;}
        }
        &.flex5{
            ul, dl{flex:1 0 19.999%;}
        }
        .col5{
            li, dd{width:19.999%;}
        }
        .flex5{
            li, dd{flex:1 0 19.999%;}
        }
    }
}