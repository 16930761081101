/** nav */
.info-nav{
    font-size:0;
    position:relative;
    ul, li { margin:0 auto; padding:0; list-style:none outside none; }
    .label, li, a{
        position:relative;
        overflow:hidden;
        transition: .3s;
    }
    .label {
        width:100%;
        background:none;
        @include font-size(14);
        @include radius(0);
        padding:10px 15px;
        z-index:1;
        &, span{display:block;}
        span{
            position:relative;
            white-space: nowrap;
            @include ellipsis (1);
            &:after{
                content:'>';
                display:block;
                line-height: 1;
                position:absolute;
                top:50%; right:0;
                transform:scale(1.5,0.9) translate(0,-50%) rotate(90deg);
                transition: .3s;
            }
        }
    }
    li.on >a{@include opacity(1);}
    a {
        display:block;
        @extend %font-size14-15;
    }
    .has-nav{
        >a:after{
            display:block;
            line-height:1;
            position:absolute;
            top:50%; right:10px;
            transition: .3s;
        }
        &.on >.sub-nav{display:block;}
    }
    .sub-nav{
        display:none;
        padding:5px 0 10px;
    }
}
.nav-tp01{
    padding:25px 0 10px;
    overflow:hidden;
    .swiper-container{
        max-width:1440px;
        padding:0 15px;
    }
    .swiper-slide{width:50%;}
    .swiper-button-prev, .swiper-button-next{
        width:26px; height:26px;
        @include opacity(1);
        margin:-13px 0 0;
    }
    .swiper-button-prev{
        background:url(#{$img}swiper01-prev.png) center no-repeat;
        left:5px;
    }
    .swiper-button-next{
        background:url(#{$img}swiper01-next.png) center no-repeat;
        right:5px;
    }
    .item-inner{
        max-width:340px;
        padding:3px 0;
        &:before{
            content:'';
            display:block;
            position:absolute;
            top:0; left:0;
            width:100%; height:100%;
            background:rgba(0,0,0,0.6);
            transform:scale(1,0.97);
            transition: .3s;
        }
    }
    .text{
        text-align:center;
        @include font-size(15);
        color:#fff;
        position:absolute;
        top:50%; left:0;
        width:100%;
        transform: translate(0,-50%);
        opacity:0.8;
        transition: .3s;
    }
    .on{
        .item-inner:before{
            background:none;
            border:3px solid $color1;
            transform:scale(1,1);
        }
        .text{
            opacity:1;
            text-shadow:0 0 10px #000;
        }
    }
}
.nav-tp02, .nav-tp03, .nav-tp04, .nav-tp05, .nav-tp06, .nav-tp07{
    text-align:center;
    padding:25px 15px 10px;
    ul{
        max-width:1020px;
        margin:0 auto;
    }
    li{
        display:inline-block;
        vertical-align:top;
        padding:2px;
    }
    a{
        padding:10px 15px;
        text-align:center;
    }
}
.nav-tp02{
    .label, .on a{
        border-bottom:5px solid $color1;
        color:$color1;
    }
    .label{border:1px solid $color1;}
    a{
        font-weight:bold;
        border-bottom:5px solid $gray01;
    }
}
.nav-tp03, .nav-tp04{
    .label, .on a{
        border:none;
        color:#fff;
    }
    a{
        background:$gray02;
        color:$color-base;
        @include opacity(0.7);
    }
}
.nav-tp03{
    .label, .on a{background:$color1;}
    ul{max-width:1240px;}
}
.nav-tp04{
    font-weight:bold;
    .label, .on a{background:linear-gradient(320deg, $color2 0, $color1 100%);}
    .label {text-align:left;}
    a{padding:16px 10px;}
    .on a{
        padding:10px;
        &:after{
            content:'';
            display:block;
            width:7px; height:7px;
            margin:5px auto 0;
            border-style: solid;
            border-width:0 0 1px 1px;
            border-color:#fff;
            transform:rotate(-45deg);
        }
    }
}
.nav-tp05, .nav-tp06{
    .label, a{@include radius(3px);}
    a{@include opacity(0.5);}
    &.nav-switch{
        li{padding:0;}
        a{
            @include radius(0);
            border-top:0 none;
        }
    }
}
.nav-tp05{
    .label, a{border:1px solid lighten($color-light,10%);}
    .on a{
        background:$color1;
        color:#fff;
        border:1px solid $color1;
    }
}
.nav-tp06{
    .label, .on a{
        border:3px solid $color1;
        font-weight:bold;
        color:$color1;
    }
    ul {max-width:1360px;}
    a{border:3px solid lighten($color-light,30%);}
}
.nav-tp07{
    padding:25px 0 0;
    li{
        width:33.333%;
        padding:0;
        max-width:180px;
    }
    a{
        @include opacity(0.7);
        &:before{
            @extend %fake-border;
            border:1px solid $gray03;
            border-bottom:3px solid $gray03;
        }
    }
    .on a{
        color:$color1;
        @include opacity(1);
        &:before{
            border:1px solid $color1;
            border-bottom:3px solid $color1;
        }
    }
}
.nav-switch, .sidenav-tp01, .sidenav-tp02{
    >ul {display:none;}
    li{
        display:block;
        //padding:1px 0;
        //&.on{display:none;}
    }
    &.on {
        > ul { display:block;}
        transform:scale(1.5,0.9) translate(-2px,-50%) rotate(-90deg);
    }
}
.sidenav-tp01, .sidenav-tp02, .sidenav-tp03{
    padding:0 0 20px;
    .label {
        text-align:center;
        font-weight:bold;
        padding:10px 15px;
    }
    .sub-nav{
        a{@include opacity(0.7);}
        .on >a{
            @include opacity(1);
            font-weight:bold;
        }
    }
    .node01, >ul{
        box-shadow:0 0 5px rgba(0,0,0,0.1);
        >li{
            border-bottom:1px solid $gray02;
            >a{
                @extend %font-size14-15;
                padding:10px 30px 10px 15px;
            }
        }
    }
    .node02, >ul>li>.sub-nav>ul{
        >li{
            >a{
                padding:8px 10px;
                @include font-size(13);
            }
            &.on{
                >a{color:$color1;}
            }
        }
    }
    .node03, .node04{
        >li{
            >a{@include font-size(12);}
        }
    }
    .node03, >ul>li>.sub-nav>ul>li>.sub-nav>ul{
        >li{
            >a{padding:5px 20px;}
            &.on{
                border-left:1px solid $color1;
                >a{color:$title-color;}
            }
        }
    }
    .node04{
        >li{
            >a{padding:5px 30px;}
            &.on >a{color:$color1;}
        }
    }
}
.sidenav-tp01{
    &.on .label{
        background:$color1;
        color:#fff;
    }
    .label {
        background:$gray02;
        border:none;
    }
    a{border-left:2px solid rgba(0,0,0,0);}
    >ul >li{
        border-bottom:1px solid $gray02;
        &.on >a{
            background:$gray02;
            font-weight:bold;
            color:$title-color;
            border-left:2px solid $color1;
        }
    }
    .has-nav{
        >a:after{
            content:'+';
            @include font-size(18);
            font-weight:100;
            color:#999;
            transform: translate(0,-50%);
        }
        &.on >a:after{
            content:'--';
            letter-spacing:-3px;
            margin-right:3px;
        }
    }
}
.sidenav-tp02{
    &.on .label{
        background:none;
        border:2px solid $color1;
        color:$color1;
    }
    .label {
        background:$color-base;
        color:$body-bg;
        border:2px solid $color-base;
    }
    .node01, >ul>li{
        &.on >a{
            background:$color1;
            font-weight:bold;
            color:#fff;
            &:after{color:#fff;}
        }
    }
    .has-nav{
        >a:after{
            content:'>';
            font-family:Arial,$font-ms;
            @include font-size(18);
            font-weight:700;
            color:$color1;
            transform:scale(0.7,1) translate(0, -50%);
        }
        &.on >a:after{
            font-weight:500;
            transform: translate(0,-50%) scale(1.2,0.7) rotate(90deg);
            right:13px;
        }
    }
    .sub-nav{
        >li{
            &.on>a:before{
                @extend %btn-icon;
                width:7px; height:2px;
                background:$color1;
                margin:-2px 10px 0 0;
            }
        }
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {
    .nav-tp01{
        .swiper-slide{width:33.333%;}
        .text{@include font-size(19);}
        .swiper-slide-active .text{@include font-size(23);}
    }
}
@media screen and (min-width: 768px) {
    .nav-tp01{
        a{padding:10px 15px;}
    }
}
@media screen and (min-width: 769px) {
    .info-nav{
        .label { display:none; }
        a:hover{@include opacity(1);}
        .sub-nav{
            padding:10px 0 15px;
            a:hover{color:$title-color;}
            .sub-nav{padding:5px 0 10px;}
        }
    }
    .nav-tp01{
        padding:50px 0 30px;
        .swiper-container{padding:0 40px;}
        .swiper-button-prev{left:20px;}
        .swiper-button-next{right:20px;}
        .swiper-slide{width:25%;}
        .item-inner:hover{
            &:before{background:none;}
            .text{
                @include font-size(23);
                opacity:1;
                text-shadow:0 0 10px #000;
            }
        }
    }
    .nav-switch{
        ul{
            display:block;
            text-align:center;
        }
        li{
            display:inline-block;
            vertical-align:top;
            &.on{display:inline-block;}
        }
    }
    .nav-tp02, .nav-tp03, .nav-tp04, .nav-tp05, .nav-tp06, .nav-tp07{
        padding:50px 15px 20px;
        a{min-width:150px;}
    }
    .nav-tp02{
        li{padding:0 10px;}
        a:hover, &.nav-switch a:hover{
            border-bottom:5px solid $color1;
            color:$color1;
        }
        &.nav-switch{
            a{border-bottom:5px solid $gray01;}
            .on a{border-bottom:5px solid $color1;}
        }
    }
    .nav-tp03, .nav-tp04, .nav-tp05, .nav-tp06{
        li{padding:2px;}
        &.nav-switch li{padding:2px;}
    }
    .nav-tp03, .nav-tp05, .nav-tp06{
        a{padding:10px 20px;}
    }
    .nav-tp03{
        a:hover{
            background:$color1;
            color:#fff;
        }
        .on a:after{
            content:'';
            display:inline-block;
            vertical-align:middle;
            width:16px; height:10px;
            background:url(#{$img}nav03-arr.png) center no-repeat;
            background-size:contain !important;
            margin:0 0 1px 10px;
        }
    }
    .nav-tp04{
        a:hover{
            background:linear-gradient(320deg, $color2 0, $color1 100%);
            color:#fff;
        }
    }
    .nav-tp05{
        &.nav-switch{
            a{
                border:1px solid lighten($color-light,10%);
                @include radius(3px);
            }
            .on a{border:1px solid $color1;}
        }
    }
    .nav-tp06{
        &.nav-switch{
            a{
                border-top:3px solid lighten($color-light,30%);
                @include radius(47px);
            }
           .on a{border:3px solid $color1;}
        }
    }
    .nav-tp07{
        a:hover{
            color:$color1;
            &:before{
                border:1px solid $color1;
                border-bottom:3px solid $color1;
            }
        }
    }
    .sidenav-tp01, .sidenav-tp02{
        .label { display:none; }
        //li.on{display:block;}
        .node01, >ul{
            display:block;
            box-shadow:none;
            border-top:1px solid $gray02;
            >li{
                >a{
                    padding:14px 30px 14px 15px;
                }
            }
        }
        .node01{
            >li>a{
                &:hover{color:$title-color;}
            }
        }
        .node02, >ul>li>.sub-nav>ul{
            >li>a{@include font-size(14);}
        }
        .node03, >ul>li>.sub-nav>ul>li>.sub-nav>ul{
            >li>a{@include font-size(13);}
        }
        .node04{
            >li>a{@include font-size(12);}
        }
    }
    .sidenav-tp01{
        >ul>li{
            >a:hover{
                background: $gray01;
                border-left: 2px solid $gray01;
            }
            &.on >a:hover{border-left: 2px solid $color1;}
        }
    }
    .sidenav-tp02{
        >ul>li{
            >a:hover{
                background:$color2;
                color:#fff;
                &:after{color:#fff;}
            }
            &.on >a:hover{background:$color1;}
        }

    }
}
@media screen and (min-width: 1280px) {}
