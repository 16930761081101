/** banner */
.banner {
    position:relative;
    margin:0 auto;
    overflow:hidden;
}
.banner-tp03, .banner-tp04{
    .figure>img {
        display: block;
        margin: 0 auto;
        visibility: hidden;
    }
    .text {
        text-align:center;
        color:#fff;
        padding:0 10px;
        position:absolute;
        left:0;
        width:100%;
        height:100%;
        top:0;
        z-index:2;
    }
    .figure {min-height:150px;}
    .title{
        @include font-size(32);
        position:relative;
        top:40%;
        text-shadow:0 0 10px rgba(0,0,0,0.3);
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    .banner-tp03{
        .figure{min-height:280px;}
        .title{@include font-size(48);}
    }
}
@media screen and (min-width: 1400px) {}