/** plugin-skin */
// swiperJs
.swiper-sec {
    position:relative;
    font-size:0;
    img { display:block; margin:0 auto;}
    .swiper-button-next, .swiper-button-prev{
        background-size:contain!important;
	    font-size: 0;
	    text-indent: -999px;
        transition: .3s;
        z-index:1;
    }
    .swiper-button-disabled { 
        pointer-events:auto; 
        display:none;
    }
    .item { box-sizing:border-box; }
    .item-inner {
        position:relative;
        display:block;
        margin:0 auto;
    }
}
.swiper-tp01{
    .swiper-button-next, .swiper-button-prev { 
        width:30px; height:30px;
    }
    .swiper-button-prev {
        left:0;
        background:url(#{$img}swiper01-prev.png) center no-repeat;
    }
    .swiper-button-next {
        right:0;
        background:url(#{$img}swiper01-next.png) center no-repeat;
    }
}
.swiper-tp02{
    .swiper-pagination{bottom:7%;}
    .swiper-pagination-bullet{
        @include opacity(1);
        width:14px; height:14px;
        background:#ddd;
        border:3px solid rgba(0,0,0,0);
        margin: 0 10px;
        transform:scale(0.57);
        &.on{
            background:none;
            border:3px solid $color1;
            transform:scale(1);
        }
    }
}
.swiper-tp03, .swiper-tp04{
    .swiper-wrapper:before{
        content:'';
        display:block;
        width:100%;
        position:absolute;
        bottom:-1px; left:0;
        z-index:2;
    }
    .swiper-container-horizontal> .swiper-pagination-bullet{margin: 0 8px;}
    .swiper-pagination-bullet{
        background:#fff;
        box-shadow:0 0 5px rgba(0,0,0,0.3);
    }
}
.swiper-tp03{
    .swiper-wrapper:before{
        height:265px;
        background-size:cover;
    }
    .swiper-pagination{bottom:5%;}
    .swiper-pagination-bullet{
        width:14px; height:14px;
        @include opacity(1);
        border:3px solid rgba(0,0,0,0);
        transform:scale(0.57);
        &.on{
            background:none;
            border:3px solid $color1;
            box-shadow:none;
            transform:scale(1);
        }
    }
}
.swiper-tp04{
    .swiper-wrapper:before{
        height:180px;
        background-size:contain;
    }
    .swiper-pagination{bottom:10%;}
    .swiper-pagination-bullet{
        width:10px; height:10px;
        @include opacity(0.5);
        &.on{@include opacity(1);}
    }
}
@media screen and (min-width: 480px){}
@media screen and (min-width: 640px){}
@media screen and (min-width: 768px){}
@media screen and (min-width: 769px){
    .swiper-tp01{
        .swiper-button-next, .swiper-button-prev{width:60px; height:60px;}
    }
    .swiper-tp03, .swiper-tp04{
        .swiper-container-horizontal .swiper-pagination-bullet{margin: 0 12px;}
    }
    .swiper-tp04{
        .swiper-pagination{bottom:15%;}
    }
}
@media screen and (min-width: 1200px){}
@media screen and (min-width: 1500px){}