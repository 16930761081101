/** done-skin */
.done {
    max-width:960px;
    margin:0 auto;
    padding:20px 10px 50px;
    text-align:center;
    p {
        @include font-size(13);
        color:#666;
        margin:0 auto 30px;
    }
    .btn {
        max-width:280px;
        margin:10px auto 0;
    }
}

@media screen and (min-width: 768px) {
    .done {
        padding:0 0 50px;
        .btn { margin:50px auto 0; }
    }
}
@media screen and (min-width: 1200px) {
    .done {
        padding:100px 0;
        min-height:400px;
    }
}